import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"


export default function AccountantsRegistration() {
    return (
      <Layout>
        <SEO
          title="Accounting and Bookkeeping Registration"
          description="Register to join taxomate partner program."
          image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
          pathname="/accounting-bookkeeping-registration/"
          originalPathname="/accounting-bookkeeping-registration/"
        />

<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSced0RLX8OlKejCbhHxr1ryLTanB3W8XB0gslxWCr3_zfMGcg/viewform?embedded=true" width="1200" height="3375" ></iframe>

        </Layout>
)}